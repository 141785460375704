import React, { useContext, lazy } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import RouteLayout from "./Layouts/RouteLayout";
import Login from "./Security/Login";
import ForgotPassword from "./Security/ForgotPassword";
import ResetPassword from "./Security/ResetPassword/ResetPassword";
import Register from "./Security/Register/Register";

/* ------------- CSS ------------- */
import "./tailwind/output.css";
import "./Components/ReviewPDF/ReviewPDF.css";

import { StoreContext, StoreProvider } from "./Datastore/Store";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  ApolloLink,
  RequestHandler,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import Snackbar from "./Components/Notifications/Snackbar";
import { NetworkStatusIndicator } from "./Components/NetworkStatus";
import DocumenttemplateDownload from "./Pages/DocumentTemplater/Template/Download";

const Home = lazy(() => import("./Pages/Home"));
const GroupPage = lazy(() => import("./Pages/Group&Client"));
const AdvancedSearch = lazy(() => import("./Pages/AdvancedSearch"));
const UserManagement = lazy(() => import("./Pages/Settings"));
const TasksModule = lazy(() => import("./Pages/TasksModule"));
const Page404 = lazy(() => import("./Pages/404"));
const Metrics = lazy(() => import("./Pages/Metrics"));
const ReviewPDF = lazy(() => import("./Components/ReviewPDF"));
const DataVerification = lazy(() => import("./Pages/DataVerification"));
const LVPOPage = lazy(() => import("./Pages/LVPO"));
const AdviceModule = lazy(() => import("./Pages/Advice"));
const DocumentTemplater = lazy(() => import("./Pages/DocumentTemplater"));
const AdviceOverviewDashboard = lazy(() => import("./Pages/AdviceDashboard"));
const DocumentDownload = lazy(() => import("./Pages/Document/Download"));
const CreateOrganisation = lazy(() => import("./Pages/CreateOrganisation"));

function App() {
  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_HOST,
    credentials: "include",
    // headers: {
    //   "Apollo-Require-Preflight": "true",
    // },
  });

  const wsLink: ApolloLink | RequestHandler = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_WS_HOST ?? "",
      lazy: true,
      keepAlive: 10000,
      connectionParams: {
        credentials: "include",
      },
    })
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) =>
        console.log(`GraphQL Error: ${error.message}`, error)
      );
    }
    if (networkError) {
      console.log(`Network Error: ${networkError.message}`);
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const combinedLinks = ApolloLink.from([errorLink, splitLink]);

  // const PAGINATION_POLICY = {
  //   keyArgs: "where",
  //   merge: (
  //     existing,
  //     incoming,
  //     { args: { skip = undefined, take = undefined } }
  //   ) => {
  //     // console.log("MERGE: ", skip, take, existing, incoming);
  //     if ((skip || skip === 0) && (take || take === 0)) {
  //       if (existing) {
  //         const existingIDs = (existing ?? []).map((entry) => entry.ID);
  //         incoming = incoming.filter(
  //           (entry) => !existingIDs.includes(entry.ID)
  //         );
  //       }
  //       // console.log(
  //       //   "MERGE: YES SKIP AND TAKE\n",
  //       //   skip,
  //       //   take,
  //       //   incoming,
  //       //   [...(existing ?? []), ...incoming].length
  //       // );
  //       // console.log("MERGE: SKIP AND TAKE YES ", existing, incoming, [
  //       //   ...(existing ?? []),
  //       //   ...incoming,
  //       // ]);
  //       return [...(existing ?? []), ...incoming];
  //     } else {
  //       // Only need to return incoming in this case, even if only skip
  //       // or only take or none. This is because skip or take individually, or none,
  //       // don't trigger pagination, the combination of skip and take does trigger
  //       // pagination. E.g. { skip: 10 } just means start after first 10. { take: 10 } just means
  //       // only show me the first 10. { skip: 10, take: 10 } means show me a paginated selection.

  //       if (existing) {
  //         const existingIDs = (existing ?? []).map((entry) => entry.ID);
  //         incoming = incoming.filter(
  //           (entry) => !existingIDs.includes(entry.ID)
  //         );
  //       }
  //       console.log("MERGE: NO SKIP AND TAKE\n", skip, take, [
  //         ...(existing ?? []),
  //         ...incoming,
  //       ]);
  //       return [...(existing ?? []), ...incoming];
  //     }
  //   },
  //   read(existing, { args: { skip = undefined, take = undefined } }) {
  //     // FOR SOME REASON WE HAVE TO HAVE THE READ FUNCTION NOT RETURNING ANYTHING?!?!?!?
  //     if ((skip || skip === 0) && (take || take === 0)) {
  //       // A read function should always return undefined if existing is
  //       // undefined. Returning undefined signals that the field is
  //       // missing from the cache, which instructs Apollo Client to
  //       // fetch its value from your GraphQL server.
  //       // console.log(
  //       //   "READ: YES SKIP AND TAKE",
  //       //   skip,
  //       //   take,
  //       //   existing && existing.slice(skip, skip + take)
  //       // );
  //       // return existing && [...existing.slice(skip, skip + take)];
  //     } else if (skip || skip === 0) {
  //       // console.log("READ: ONLY SKIP", existing && existing.slice(skip));
  //       return existing && [...existing.slice(skip)];
  //     } else if (take || take === 0) {
  //       // console.log("READ: ONLY TAKE", existing && existing.slice(0, take));
  //       return existing && [...existing.slice(0, take)];
  //     } else {
  //       console.log("READ: NO SKIP AND TAKE\n", skip, take, existing);
  //       return existing;
  //     }
  //   },
  // };

  const apollo = new ApolloClient({
    link: combinedLinks,
    cache: new InMemoryCache({
      typePolicies: {
        // Query: {
        //   fields: {
        //     findManyAdvice: PAGINATION_POLICY,
        //     findManyGroups: PAGINATION_POLICY,
        //     findManyOpportunities: PAGINATION_POLICY,
        //     findManyProject: PAGINATION_POLICY,
        //     findManyProjecttask: PAGINATION_POLICY,
        //     findManyTasks: PAGINATION_POLICY,
        //   },
        // },
        user: {
          keyFields: ["staff_ID", "staff_organisation_ID"],
        },
      },
    }),
  });

  /**
   * @todo Refactor to react router v6. The way we currently handle nested routes in here is ridiculous
   */
  const App = () => {
    const [context] = useContext(StoreContext);
    return (
      <>
        <NetworkStatusIndicator />

        {context.snackbar.open && (
          <Snackbar
            type={context.snackbar.type}
            message={context.snackbar.message}
            open={context.snackbar.open}
          />
        )}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="login" />} />
            <Route path="login" element={<Login />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="reset/:token" element={<ResetPassword />} />
            <Route path="register/:token" element={<Register />} />

            {/* ROUTE LAYOUT HERE AS A PARENT ROUTE SO IT ALWAYS RENDERS */}
            {/* Suspense is handled in RouteLayout as well */}
            <Route path="" element={<RouteLayout />}>
              {/* ------------- 404 ------------- */}
              <Route path="*" element={<Page404 />} />
              <Route path="home" element={<Home />} />

              <Route path="advancedsearch" element={<AdvancedSearch />} />
              <Route path="MOASadmin" element={<CreateOrganisation />} />

              {/* Group page */}
              <Route path="group/ID/:groupsid/*" element={<GroupPage />} />

              <Route path="tasks-module/*" element={<TasksModule />} />

              <Route path="settings/*" element={<UserManagement />} />

              {/* <Route path="style" element={<SortableMultipleContainers />} /> */}

              {/* ------------- ADMIN PORTAL ------------- */}
              <Route path="staff/:staffID/metrics/*" element={<Metrics />} />

              {/* ------------- REVIEW MODULE ------------- */}

              <Route
                path="review-pdf/ID/:id/group/ID/:groupsid"
                element={<ReviewPDF />}
              />

              {/* ------------ ADVICE AGREEMENT ------------ */}

              {/* <Route
                path="adviceagreement-pdf/ID/:id/group/ID/:groupsid"
                element={<AdviceAgreementPDF />}
              /> */}

              {/* ---------------- DATA VERIFICATION --------------------- */}

              <Route
                path="data-verification/group/ID/:groupsid/*"
                element={<DataVerification />}
              />

              {/* ---------------- ADVICE DASHBOARD --------------------- */}

              <Route
                path="advice-module/*"
                element={<AdviceOverviewDashboard />}
              />

              {/* ------------- Advice MODULE ------------- */}
              <Route
                path="advice/ID/:advice_ID/group/ID/:groupsid/*"
                element={<AdviceModule />}
              />
              {/* path="adviceagreement-pdf/ID/:id/group/ID/:groupsid" */}

              <Route
                path="advice/ID/:advice_ID/group/ID/:groupsid/document-pdf/ID/:documentid"
                element={<DocumentDownload />}
              />

              {/* ------------- Document Templator ------------- */}
              <Route path="templater/*" element={<DocumentTemplater />} />

              <Route
                path="documenttemplate-pdf/ID/:documenttemplateid"
                element={<DocumenttemplateDownload />}
              />

              {/* LVPO */}
              <Route path="lvpo/group/ID/:groupsid" element={<LVPOPage />} />
            </Route>
          </Routes>

          {/* ------------- 404 ------------- */}
          {/* <Route path="*" element={<Page404 />} /> */}
        </BrowserRouter>
      </>
    );
  };

  return (
    <ApolloProvider client={apollo}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </ApolloProvider>
  );
}

export default App;
