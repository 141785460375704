import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FullPageLoader from "../../Components/Loaders/FullPageLoader";
import { Input } from "../../Components/Inputs/Input";

import { Button } from "../../Components/Buttons";
import {
  useAuthenticateMutation,
  useFindCurrentUserQuery,
} from "../../codegen/schema";
import clsx from "clsx";

export default function Login() {
  document.title = "MOAS | Login";
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [authFunction, auth] = useAuthenticateMutation();

  const {
    data: { findCurrentUser: user } = {},
    error: userError,
    loading: userLoading,
  } = useFindCurrentUserQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    if (
      (auth.data ? (auth.data.authenticate === true ? true : false) : false) ||
      (user && !userError && !userLoading)
    ) {
      navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, auth]);

  function validateEmail(email: string | undefined): boolean {
    var valid = false;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email && emailRegex.test(email)) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  }

  function validatePassword(password: string | undefined): boolean {
    var valid = false;

    if (password) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  }

  function validateForm(
    email: string | undefined,
    password: string | undefined
  ) {
    let valid = false;

    if (validateEmail(email)) {
      valid = true;
    } else {
      valid = false;
    }

    if (validatePassword(password)) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    var email;
    var password;

    Array.from(document.getElementsByTagName("input")).forEach((input) => {
      if (input.id === "email") {
        email = input.value;
      } else if (input.id === "password") {
        password = input.value;
      }
    });

    if (validateForm(email, password)) {
      if (!email || !password) return;
      authFunction({
        variables: { email, password },
      })
        .then((result) => console.log(result))
        .catch((error) => console.log(error));
    }
  }

  function renderLoading() {
    if (auth.loading) {
      return <FullPageLoader />;
    }
  }

  return (
    <div className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-24 w-auto"
          src={
            process.env.REACT_APP_PUBLIC_CLOUDSTORAGE_HOST +
            "/website/logos/1200pxX500px_default.png"
          }
          alt="MOAS logo"
        />
        <h2 className="mt-8 text-center text-2xl font-display font-bold leading-9 tracking-tight text-neutral-800">
          Sign in to your account
        </h2>
      </div>
      {renderLoading()}

      <div className="flex flex-col items-center">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-neutral-500"
              >
                Email address
              </label>
              <Input id={"email"} name="email" required />
            </div>
            <div style={{ position: "relative" }}>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-neutral-500"
                >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/forgotpassword"
                    className="font-semibold text-blue-500 hover:text-blue-200"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <Input
                id={"password"}
                name="password"
                type={showPassword ? "text" : "password"}
                required
              />
              <div
                className={clsx(
                  showPassword ? "fa-lock-open" : "fa-lock",
                  "icon fa-regular text-neutral-500 absolute cursor-pointer right-2 top-12"
                )}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            {auth.error ? (
              <div className="flex gap-2 itens-center">
                <i className="icon text-semantic-error-500 fa-regular fa-circle-exclamation" />
                <h5 className="text-semantic-error-500">
                  Email or password is incorrect.
                </h5>
              </div>
            ) : (
              ""
            )}
            <Button type="default" buttontype="submit" text="Login" />
          </form>
        </div>
      </div>
    </div>
  );
}
