import React, { FocusEventHandler, useEffect, useState } from "react";
// import CurrencyInput from "react-currency-input-field";
import dayjs from "dayjs";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { checkFieldIsEmpty } from "../../../Functions/GeneralFunctions";
import clsx from "clsx";
import { Tooltip, TooltipProps } from "../../Tooltip";

const dollarMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

interface Props {
  id?: string; // nevessary for required error
  name: string; // DB name to be submitted
  label?: string; // label value
  styleType?: "default" | "no-style";
  defaultValue?: string | number | null | undefined;
  className?: string;
  required?: boolean; // Required or not for submission
  disabled?: boolean;
  type?:
    | "date"
    | "dollar"
    | "number"
    | "text"
    | "percent"
    | "checkbox"
    | "password";
  placeholder?: string; // Placeholder inside input when empty
  onChange?: Function;
  onClick?: Function;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>; // onB.lur function to execute when input deselected.
  maxLength?: number; // The max length of characters that can be in the input
  min?: number; // The min value that can be in the input
  step?: number; // The input step
  helpertext?: string;
  hidden?: boolean;
  adornment?: string;
  tooltip?: Omit<TooltipProps, "children">;
}

/**
 * @created 01-07-2021
 * @updated 02-05-2023
 * @description Input component.
 */
export const Input: React.FC<Props> = ({
  id,
  name,
  label,
  defaultValue,
  className,
  required,
  disabled,
  type = "text",
  styleType = "default",
  placeholder,
  onChange,
  onClick,
  maxLength,
  min,
  onBlur,
  step,
  helpertext,
  hidden,
  adornment,
  tooltip,
}) => {
  const dollarMask = createNumberMask(dollarMaskOptions);

  const [value, setValue] = useState(
    defaultValue !== undefined && defaultValue !== null
      ? type === "date"
        ? dayjs(defaultValue).isValid()
          ? dayjs(defaultValue).format("YYYY-MM-DD")
          : ""
        : defaultValue
      : ""
  );

  useEffect(() => {
    if (value !== defaultValue)
      setValue(
        defaultValue !== undefined && defaultValue !== null
          ? type === "date"
            ? dayjs(defaultValue).isValid()
              ? dayjs(defaultValue).format("YYYY-MM-DD")
              : ""
            : defaultValue
          : ""
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const inputVariables = {
    checked: type === "checkbox" ? value === 1 : undefined,
    id,
    name,
    type: type === "percent" ? "number" : type,
    required,
    disabled,
    placeholder,
    maxLength,
    min,
    value,
    step,
    // Auto complete and lp ignore to stop lastpass pre-filling incorrectly
    autoComplete: "off",
    "data-lpignore": "true",
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      var customEvent = {
        ...event,
        target: {
          ...event.target,
          name,
          value:
            type === "dollar"
              ? event.target.value.replaceAll(",", "").replaceAll("$", "")
              : event.target.value,
          type,
        },
      };

      return onBlur && onBlur(customEvent);
    },
    onClick: (event: React.MouseEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onClick && onClick(event);
    },
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      onChange && onChange(event);
    },
  };

  return !hidden ? (
    <div className={clsx(label ? "mt-3" : "", "relative w-full")}>
      {disabled ? (
        <p className={"input-style"}>{checkFieldIsEmpty(value, type)}</p>
      ) : type === "dollar" ? (
        <MaskedInput
          // className={inputClassname}
          mask={dollarMask}
          {...inputVariables}
        />
      ) : (
        <>
          {adornment && (
            <i
              className={clsx(
                adornment,
                "icon text-neutral-300 fa-2xs absolute top-0 h-11 left-2"
              )}
            />
          )}
          <input
            {...inputVariables}
            className={clsx(adornment ? "adornment" : "", "peer")}
          />
          {type === "percent" && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <div
                className="icon h-5 w-5 fa-regular fa-percent text-neutral-400"
                aria-hidden="true"
              />
            </div>
          )}
        </>
      )}
      <label
        htmlFor="name"
        className={clsx(
          disabled ? "disabled" : "",
          "input-label",
          "peer-focus:text-blue-500 peer-focus:bg-white"
        )}
      >
        <div className="flex items-center gap-1">
          {label}
          {tooltip && (
            <Tooltip
              title={tooltip.title ?? ""}
              background={tooltip.background ?? "dark"}
              placement={tooltip.placement ?? "top"}
            >
              <div className="icon text-neutral-500 fa-regular fa-lg fa-circle-question" />
            </Tooltip>
          )}
        </div>
      </label>
      {helpertext && (
        <p className="mt-2 text-xs text-neutral-400">{helpertext}</p>
      )}
    </div>
  ) : (
    <input {...inputVariables} hidden />
  );
};
